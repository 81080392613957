import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_toolbar_title = _resolveComponent("v-toolbar-title")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_app_bar = _resolveComponent("v-app-bar")!

  return (_openBlock(), _createBlock(_component_v_app_bar, { app: "" }, {
    default: _withCtx(() => [
      _createVNode(_component_v_toolbar_title, { href: "#home" }, {
        default: _withCtx(() => [
          _createTextVNode(" Jared Joyce ")
        ]),
        _: 1
      }),
      _createVNode(_component_v_spacer),
      _createVNode(_component_v_btn, {
        "d-none": "",
        "d-md-flex": "",
        href: "#about"
      }, {
        default: _withCtx(() => [
          _createTextVNode("About")
        ]),
        _: 1
      }),
      _createVNode(_component_v_btn, {
        "d-none": "",
        "d-md-flex": "",
        href: "#experience"
      }, {
        default: _withCtx(() => [
          _createTextVNode("Experience")
        ]),
        _: 1
      }),
      _createVNode(_component_v_btn, {
        "d-none": "",
        "d-md-flex": "",
        href: "#projects"
      }, {
        default: _withCtx(() => [
          _createTextVNode("Projects")
        ]),
        _: 1
      }),
      _createVNode(_component_v_btn, {
        "d-none": "",
        "d-md-flex": "",
        href: "#contact"
      }, {
        default: _withCtx(() => [
          _createTextVNode("Contact")
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}